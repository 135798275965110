import React from 'react'

const DownloadApp = () => {
    const google_link = <a href="https://play.google.com/store/apps/details?id=com.helpbridge"> Google PlayStore </a>
    const apple_link = <a href="https://apps.apple.com/us/app/helpbridge-24-7-migrant-info/id1591227287"> AppStore</a>

    return (
        <div style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100vh',
            width: "100hw",
            flexWrap: "wrap"
        }}>
            <div>  
                <p>Please download our official app at</p>
                <p> &nbsp; {google_link} &nbsp; or &nbsp; {apple_link}</p>
            </div>


        </div>
    )
}

export default DownloadApp