
// export const API_BASE_URL = 'https://bridge.ezxdemo.com/api';
export const API_BASE_URL = 'https://admin.helpbridge.co/api';
// export const API_BASE_URL = 'http://localhost:3001';
export const ACCESS_TOKEN_NAME = 'login_access_token';
export const TOKEN_USER = 'user_name';
export const USERID = 'user_id';
export const USERROLE = 'user_role';
export const GOOGLE_CLIENT_ID = '813547511980-4gk49m7p6pmnqn2gt3jklibngukagibu.apps.googleusercontent.com'
export const FACEBOOK_ID = '1288408134867009'
